
.modal-pop-back-eng{
    position: relative;
    width: 757px !important;
    min-height: 200px;
    background: #fff;
    margin: 0 auto;
    margin-top: 7%;
    height: 540px !important;
    overflow: hidden !important;
    border-radius: 14px;
    border: none;
    outline: none;
 }
.ReactModal__Overlay{
 background-color: #584f4fc9 !important;
opacity: 0;
 transform: translateY(-10px);
  transition: all 500ms ease-in-out;
 }
.ReactModal__Overlay--after-open {
opacity: 1;
 transform: scale(1);
  }
 .ReactModal__Overlay--before-close {
 opacity: 0;
transform: translateY(0px);
 }
.selected{
     background-color: #c5c5c5 !important;
    
 }

 .submene-active{
    background: #f3e4d2;
  }
    
  .menu-act{
    border-bottom: 4px solid rgb(255, 134, 71);
    z-index: 1;
    position: relative;
  }
  .menu-active{
    background: #CAE4FF;
     }
    
  @media screen and (max-width: 767px) {
    .modal-pop-back-eng{
      margin-top: 28%;
      width: 362px !important;
      height: 560px !important;
    }
  }