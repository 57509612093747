@media screen and (max-width: 767px) {
    .rowMob {
        flex-direction: column !important;
    }

    .pill-center {
        margin: 0 auto;
    }
}

.ActionableCollaborate {
    width: 500px;

    @media (min-width: 320px) and (max-width: 480px) {
        height: 100%;
        width: 250px;
    }

    @media screen and (max-width: 767px) {

        width: 100%;
        position: initial;
    }
}

.IterativeCollaborate {
    width: 600px;
    position: relative;
    top: -25px;

    @media (min-width: 320px) and (max-width: 480px) {
        height: 100%;
        width: 250px;
    }

    @media screen and (max-width: 767px) {

        width: 100%;
        position: initial;
    }

    @media (min-width: 576px) and (max-width: 1400px) {
        .IterativeCollaborate {
            width: 550px;
            margin-top: -100px;
        }
    }
}


@media (min-width: 320px) and (max-width: 480px) {
    .mob-img-fix {
        height: 100%;
        width: 100%;
        position: relative;
        left: -22px;
    }

    .mob-gif-fix-1 {
        height: auto;
        width: 100%;
        position: relative;
    }
}

.des-fix-img {
    height: 300px;
}

.des-fix-img1 {
    height: 450px;
}

/* @media (min-width: 576px) and (max-width: 1400px) {
    .des-fix-images {
        width: 570px;
        position: relative;
        top: -43px;
        left: 33px;
    }
} */


@media  screen and (max-width: 1366px) {
     .des-fix-images {
        left: 10px;
        position: relative;
        top: 0;
        width: 600px;
    }
}
 

@media  screen and (max-width: 1280px) {
     .des-fix-images {
        left: 8px;
        position: relative;
        top: 0px;
        width: 600px;
    }
}


 @media (min-width: 320px) and (max-width: 480px) {
     .des-fix-images {
        height: 100%;
        width: 100%;
        position: relative;
        left: -22px;
    }
}

.des-image-research-elite {
    width: 650px;
    /* margin-top: -100px; */
}

@media (min-width: 320px) and (max-width: 480px) {
    .des-image-research-elite {
        height: 100%;
        width: 250px;
    }
}

@media screen and (max-width: 767px) {
    .des-image-research-elite {
        width: 100%;
        /* position: initial; */
    }
}

@media (min-width: 576px) and (max-width: 1400px) {
    .des-image-research-elite {
        width: 550px;
        margin-top: -100px;
    }
}


 @media (min-width: 320px) and (max-width: 480px) {
   .btn-hide {
    display: none !important;
   }

  }