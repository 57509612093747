.Marquees{
	overflow: hidden;
    position: relative;
}
.Marquee{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  /* height: 17vh; */
}
.marquee{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: 0px 5.02px 10.03px 0px #2F2B431A;
  height: 66px;
  width: 183px;
  margin: 10px;
}

.icon {
	color: #cccccc;
    transform: scale( 1.5 );
    width: 70px;
}
.icon1{
    width: 34px;
}
/* .FirstRow{
animation: Scroll 60s linear infinite;}
.SecondRow{
  animation: Scroll 60s linear infinite;
  animation-direction: reverse;
} */
.overlayLeft{
    width: 20%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.35898109243697474) 60%);
    left: 0;
}
.overlayRight{
    width: 20%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0.35898109243697474) 0%, rgba(255,255,255,1) 60%);
    right: 0;
    top: 0;
}

/* @keyframes Scroll {
    0% {
        transform: translateX(0vw);
        -webkit-transform: translateX(-50vw);
    }
      100% {
          transform: translateX(0vw);
          -webkit-transform: translateX(50vw);
        }
    } */
.Hover{
    cursor: pointer;
}
.Wrap{
    position: relative;
}
.Card{
    width: 261px;
    height: 329px;
    background-color: #fff5f0f2;
    border-radius: 23px;
    position: absolute;
    top: 0;
}
.LocName{
    font-family: Roboto;
    font-size: 20.79px;
    font-weight: 700;
    line-height: 24.95px;
    text-align: left;
    margin-top: 30px;
    margin-left: 20px;
    color: #191825;
}
.LocAddress{
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    line-height: 26px;
    text-align: left;
    color: #454545;
    margin-top: 15px;
    margin-left: 20px;
}
.Wrap .Card{
    visibility: hidden;
}
.Wrap:hover .Card{
    visibility: visible;
    animation: effect 1s infinite 2s;
}
.CardFlex{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 767px){

    .Marquee{
        flex-wrap: wrap;
        justify-content: center;
    }

}