.modal-popup-back-ces {
  position: relative;
  width: 960px !important;
  min-height: 200px;
  background: #fff;
  margin: 0 auto;
  margin-top: 3%;
  height: 590px !important;
  overflow: hidden !important;
  border-radius: 50px;
  border: none;
  outline: none;
}
.mg-0 {
  margin-top: 0px !important;
}

.modal-popup-SubPopUp {
  position: relative;
  width: 1050px !important;
  min-height: 200px;
  background: #fff;
  margin: 0 auto;
  margin-top: 3%;
  height: 590px !important;
  overflow: hidden !important;
  border-radius: 12px;
  border: none;
  outline: none;
}
.EmailIdDwnld {
  color: #ff7624;
  font-size: 20px;
  font-weight: 600;
  font-family: Roboto;
  margin-top: 10px;
}
.closePopup {
  float: right;
  padding: 14px;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  right: 10px;
}
.closePopup-theme {
  float: right;
  padding: 14px;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  right: 0;
  top: 10px;
}

.closePopup-booth {
  float: right;
  padding: 14px;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  right: 0;
  top: 0;
}
.closePopup svg:hover path {
  fill: #000;
}
.closePopup-booth svg:hover path {
  fill: #000;
}
.closePopup-theme svg:hover path {
  fill: #000;
}
.modal-popup-booth {
  position: relative;
  min-height: 236px;
  background: #fff;
  margin: 0 auto;
  margin-top: 7%;
  overflow: hidden !important;
  border-radius: 12px;
  border: none;
  outline: none;
  width: 421px !important;
  height: auto;
  padding: 0 33px;
  padding-right: 0px;
}
.modal-popup-Theme {
  position: relative;
  background: #fff;
  margin: 0 auto;
  margin-top: 7%;
  overflow: hidden !important;
  border-radius: 12px;
  border: none;
  outline: none;
  width: 335px !important;
  padding: 0 33px;
  padding-right: 0px;
}
.modal-popup-Theme-FousArea {
  position: relative;
  background: #fff;
  margin: 0 auto;
  margin-top: 7%;
  overflow: hidden !important;
  border-radius: 12px;
  border: none;
  outline: none;
  width: 393px !important;
  padding: 0 33px;
  padding-right: 0px;
  height: 300px;
}

.HoverText:hover svg path {
  fill: #083ea8;
}
.HoverText:hover svg {
  cursor: pointer;
}
.HoverText {
  display: flex;
  align-items: center;
  gap: 5px;
}
.link2004 {
  color: #454545;
}
.link2004:hover {
  color: #0085ff;
}

@media screen and (max-width: 1280px) {
  .modal-popup-back-ces {
    margin-top: 1%;
    height: 550px !important;
  }
  .modal-popup-SubPopUp {
    margin-top: 1%;
    height: 550px !important;
  }
}

@-moz-document url-prefix() {
  .closePopup {
    right: 9px;
  }
  .closePopup-theme {
    right: 9px;
  }
}

@media screen and (max-width: 767px) {
  .modal-popup-back-ces {
    width: 335px !important;
    margin-top: 10%;
    height: 552px !important;
  }
  .modal-popup-SubPopUp {
    width: 367px !important;
    height: 605px !important;
  }
  .closePopup {
    padding: 7px;
    position: absolute;
    right: 20px;
    top: -18px;
  }
  .closePopup-theme {
    padding: 7px;
    position: absolute;
    right: 6px;
    top: 15px;
  }
  .EmailIdDwnld {
    margin-top: 0;
  }

  .modal-popup-booth {
    width: 355px !important;
    margin-top: 10%;
  }
  .modal-popup-Theme {
    width: 307px !important;
    padding-left: 20px;
  }
  .modal-popup-Theme-FousArea {
    width: 307px !important;
    padding-left: 20px;
  }
}
