.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.darkBG {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;

  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;


}

.learnmoremodalcon {
  display: flex;
  flex-direction: column;
  margin-left: 31px;
  margin-top: 35px;

  @media (max-width:600px) {
    margin-top: 17px;
    margin-left: 7px;

    display: block;


    width: 100%;
    /* margin-top: 633px; */
  }
}

.learnMoreTextSec1 {
  padding-top: 6px;
  display: flex;
  gap: 15px;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 6px 15px 15px 15px;
  }
}

.centered {
  position: fixed;
  /* top: 50%; */
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width:1280px) {
    transform: translate(-50%, -45%);
  }

  @media (max-width:600px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }
}

/* .sharecloseicon{
  margin-top: 13px;
  margin-left: 63px;
  cursor:pointer;
  @media (max-width:600px) {
margin-left: 53px;                      
                  }
} */
.sharenewwbuttoncss {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 60px;

  @media (max-width:600px) {

    margin-left: 33px;
  }
}

.closeIcon {
  width: 25px;
  height: 25px;
  margin-top: 13px;
  margin-left: 63px;
  position: absolute;
  right: 44px;
  cursor: pointer;

  @media (max-width: 600px) {
    margin: 0%;
    z-index: 44;
    /* right: 5px;
    top: 5px; */
    background-color: #ffffff;
    /* padding: 8px; */
    border-radius: 50%;
    height: 18px;
    width: 18px;
    right: 1px;
    padding: 5px;
    top: 1px;
  }
}


.centered1 {
  position: relative;

  transform: translate(-44%, -150%);
  left: 50%;
  top: 55%;
  overflow: auto;
  height: 80vh;

  @media (max-width:1280px) {
    transform: translate(-47%, -183%);
  }

}

.learnmoreimage {
  width: 280px;
  height: 149px;
  padding-top: 1px;
  padding-left: 45px;

  @media (max-width:600px) {
    width: 90%;
    margin: auto;
    padding-left: 10px;
  }
}

.aboutmap {
  padding-top: 6px;
  display: flex;
  gap: 8px;
  flex-direction: column;

  @media (max-width:600px) {
    gap: 8px;
    padding: 10px;

  }
}

.modal {
  width: 573px;
  /* height: 400px; */
  border-radius: 5px;
  /* top: -4px
left: -7493px */
  overflow: hidden;

  background: white;
  z-index: 10;

  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  @media (max-width: 600px) {
    width: 324px;
    height: auto;
    padding-bottom: 25px;
    margin: 0px auto;
  }
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.sharepopupcontent {
  padding-left: 45px;

  @media (max-width: 600px) {
    padding-left: 16px;
  }
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.hoverimage:hover {
  background-color: #ff3e4e;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.learnmodal {
  display: flex;
  flex: 1;
  flex-direction: row;
  /* width: 1214px;
height: 578px;
 background: white; */
  /* top: 769px;
left: -1184px; */

}

.learnmorebackbanner {
  background-image: url("../Learnmorebanner.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  /* background-attachment: fixed; */
  background-size: cover;

  @media (max-width:600px) {
    padding-top: 10px;

  }
}

.learnmodemodalcentered {
  display: flex;
  flex-direction: row;
  width: 1214px;
  height: 578px;
  padding-bottom: 25px;
  background: white;
  position: relative;

  @media (max-width:1280px) {
    width: 1214px;
    height: 500px;
    overflow: auto;


  }

  @media (max-width:600px) {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: auto;
    /* overflow: scroll; */
    /* padding-bottom: 1px; */

  }

}

.paragraphPOinss {
  margin-left: 16px;

  @media (max-width:"600px") {
    margin-left: 0px;
  }
}

.emergingtrends {
  flex: 1;
  border-left: 0.5px solid rgba(0, 0, 0, 1);
  margin-left: 18px;
  padding-left: 20px;
  margin-top: 63px;

  @media (max-width: 600px) {
    width: 95%;
    border: none;
    padding-left: 0px;
    margin-left: 15px;
    /* margin: 0%; */
  }
}

.emergingtrends .sectionText {
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
    box-sizing: border-box;
    margin: auto !important;
    padding: 0px 25px;
  }
}

.sectionTextLeft {
  padding: 0px 10px 0px 0px;
}

.sectionRightText {
  padding: 0px 0px 0px 10px;
}

emergingtrends .image {
  width: 527px;
  height: 235px;
  padding: 15px;
  vertical-align: middle;
  box-sizing: border-box;

  @media (max-width: 600px) {
    width: 337px;
    height: auto;
    padding: 0%;
    margin: auto;
  }
}

/* .closeIcon {
  width: 25px;
  height: 25px;
  margin-top: 13px;
  margin-left: 63px;
  position: absolute;
  right: 44px;
  cursor: pointer;
  @media (max-width: 600px) {
    margin: 0%;
    z-index: 44;
    right: 5px;
    top: 5px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 50%;
  }
} */
.learnmoreenhanceimagee {
  width: 512px;
  height: 305px;

  /* padding-left: 21px;
     padding-top: 18px; */
  @media (max-width:600px) {
    width: 95%;
    height: auto;
    padding: 10px;

  }
}

.paragraphnnnn {
  margin-left: 23px;

  @media (max-width:600px) {
    width: 95%;

  }
}

.markets {
  display: flex;
  width: 100%;
  gap: 15px;
  margin-top: 5px;
  /* border-top: 1px solid #dddddd; */
  padding-top: 4px;

  @media (max-width:600px) {
    width: 95%;
    flex-direction: row;
    gap: 5px;
  }

}

.compitavemapimage {
  display: flex;
  width: 100%;
  gap: 15px;

  @media (max-width:600px) {
    flex-direction: row;
    width: 95%;
    gap: 5px;

  }
}

.highlighted {
  font-family: Roboto;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: justified;

}

.highlighted1 {
  font-family: Roboto;
  font-size: 14px !important;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: justified;

}

.companyScoutingNewstyle1 {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width:600px) {
    margin-left: 18px;
    gap: 9px;

  }

}

.newwstylesss {
  width: 275px;
  height: 140px;


}