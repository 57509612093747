.modal-popup-Verifymail{
    position: relative;
    height: 420px;
    background: #fff;
    margin: 0 auto;
    margin-top: 7%;
    overflow: hidden !important;
    border-radius: 40px;
    border: none;
    outline: none;
    width: 607px !important;
    box-shadow: 0px 2px 6px 0px rgba(69, 69, 69, 0.25);

    @media (min-width: 320px) and (max-width: 480px) {
      height: 400px;
    }
  }

  .collect-user-info{
    position: relative;
    height: 600px;
    min-height: 450px;
    background: #fff;
    margin: 0 auto;
    margin-top: 1%;
    overflow: hidden !important;
    border-radius: 40px;
    border: none;
    outline: none;
    width: 607px !important;
    box-shadow: 0px 2px 6px 0px rgba(69, 69, 69, 0.25);
  }

  .closePopup-verify{
    float: right;
    padding: 14px;
    cursor: pointer;
    margin-top: 20px;
    position: absolute;
    right: 10px;
    top: -5px;
  }
  .closePopup-verify svg:hover path{
    fill: #454545;
  }
  .modal-popup-SharePopup{
    position: relative;
    height: 608px;
    background: #fff;
    margin: 0 auto;
    margin-top: 1.5%;
    overflow: hidden !important;
    border-radius: 40px;
    border: none;
    outline: none;
    width: 640px !important;
    box-shadow: 0px 2px 6px 0px rgba(69, 69, 69, 0.25);
  }

  @media screen and (max-width: 1280px){
    .modal-popup-SharePopup{
      height: 550px;
    }
    .collect-user-info{
      height: 565px;
  }
  }

  @media screen and (min-width: 1400px){
    .modal-popup-SharePopup{
      height: 695px;
    }
  }
  @media screen and (max-width: 767px){
    .modal-popup-Verifymail{
        width: 345px !important;
        margin-top: 12%;
    }
    .modal-popup-SharePopup{
        width: 345px !important;
    }
    .collect-user-info{
      width: 345px !important;
      margin-top: 12%;
  }
  }