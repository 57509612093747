.home-popup {
  position: relative;
  height: 530px;
  background: #fff;
  margin: 0 auto;
  margin-top: 6%;
  overflow: hidden !important;
  border-radius: 40px;
  border: none;
  outline: none;
  width: 600px !important;
  box-shadow: 0px 2px 6px 0px rgba(69, 69, 69, 0.25);
}

.close-home-popup {
  float: right;
  padding: 14px;
  cursor: pointer;
  margin-top: 20px;
  position: absolute;
  right: 10px;
  top: -5px;
}

.close-home-popup svg:hover path {
  fill: #f9f2f2;
}

@media screen and (max-width: 1280px) {
  .home-popup {
    margin-top: 2%;
  }
}

@media screen and (max-width: 1280px) {}

@media screen and (max-width: 767px) {

  .home-popup {
    width: 345px !important;
    margin-top: 12%;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .home-popup {
    width: 345px !important;
  }
}

@media screen and (max-width: 1366px) {

  /* insert styles here */
  .home-popup {
    margin-top: 4%;
  }
}