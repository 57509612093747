/* CSS variables */

:root {
  /* Background colors */
  --bg-color-white: #ffffff;
  --bg-color-alice-blue: #eff6fe;
  --bg-color-smoky-blue: #e7f0fa;
  --bg-color-light-blue: #f4f8ff;
  --bg-color-cool-lavender: #e5efff;
  --bg-color-outer-space: #454545;
  --bg-color-old-lace: #fef4ea;
  --bg-color-raisin-black: #242424;
  --bg-color-davy-grey: #595959;
  --bg-color-nyanza: #dcfce7;

  /* Text colors */
  --text-color-outer-space: #454545;
  --text-color-dark-gray: #a8a8a8;
  --text-color-metallic-blue: #284b80;
  --text-color-dark-electric-blue: #5a687e;
  --text-color-cerulean: #15436d;
  --text-color-pumpkin: #ff7624;
  --text-color-black-coral: #5e6670;
  --text-color-cool-grey: #8d98aa;
  --text-color-ateneo-blue: #123d6e;
  --text-color-white: #ffffff;
  --text-color-black: #000000;
  --text-color-halloween-orange: #f2651b;
  --text-color-dark-liver: #515151;
  --text-color-chinese-black: #171717;

  /* Border colors */
  --border-color-lavender: #e7f0fa;
  --border-color-gainsboro: #d6dce6;
  --border-color-lapis-lazuli: #2660a2;

  /* Font size */
  --size11: 11px;
  --size12: 12px;
  --size13: 13px;
  --size14: 14px;
  --size15: 15px;
  --size16: 16px;
  --size17: 17px;
  --size18: 18px;
  --size19: 19px;
  --size20: 20px;
  --size21: 21px;
  --size22: 22px;
  --size23: 23px;
  --size24: 24px;
  --size25: 25px;
  --size26: 26px;
  --size27: 27px;
  --size28: 28px;
  --size29: 29px;
  --size30: 30px;
  --size31: 31px;
  --size32: 32px;
  --size33: 33px;
  --size34: 34px;
  --size35: 35px;
  --size36: 36px;
  --size37: 37px;
  --size38: 38px;
  --size39: 39px;
  --size40: 40px;
  --size41: 41px;
  --size42: 42px;
  --size43: 43px;
  --size44: 44px;
  --size45: 45px;
  --size46: 46px;
  --size47: 47px;
  --size48: 48px;
  --size49: 49px;
  --size50: 50px;
  --size51: 51px;
  --size52: 52px;
  --size53: 53px;
  --size54: 54px;
  --size55: 55px;
  --size56: 56px;
  --size57: 57px;
  --size58: 58px;
  --size59: 59px;
  --size60: 60px;
  --size61: 61px;
  --size62: 62px;
  --size63: 63px;
  --size64: 64px;
  --size65: 65px;
  --size66: 66px;
  --size67: 67px;
  --size68: 68px;
  --size69: 69px;
  --size70: 70px;
  --size71: 71px;
  --size72: 72px;
  --size73: 73px;
  --size74: 74px;
  --size75: 75px;
  --size76: 76px;
  --size77: 77px;
  --size78: 78px;
  --size79: 79px;
  --size80: 80px;
  --size81: 81px;

  /* Font weight */
  --fw200: 200;
  --fw300: 300;
  --fw400: 400;
  --fw500: 500;
  --fw600: 600;
  --fw700: 700;
  --fw800: 800;
  --fw900: 900;

  /* Lineheight */
  --lh12: 12px;
  --lh13: 13px;
  --lh14: 14px;
  --lh15: 15px;
  --lh16: 16px;
  --lh17: 17px;
  --lh18: 18px;
  --lh19: 19px;
  --lh20: 20px;
  --lh21: 21px;
  --lh22: 22px;
  --lh23: 23px;
  --lh24: 24px;
  --lh25: 25px;
  --lh26: 26px;
  --lh27: 27px;
  --lh28: 28px;
  --lh29: 29px;
  --lh30: 30px;
}

.usps-section-mobile{
  @media (min-width: 320px) and (max-width: 480px) {
    height: 300px;
    width: 100%;
    margin-left: inherit;
  }
}
.Humanoid-section-mobile{
  @media (min-width: 320px) and (max-width: 480px) {
    height:100%;
    width: 100%;
    /* margin-left: inherit; */
  }
}
.customertestimonial-mobile{
  @media (min-width: 320px) and (max-width: 480px) {
    height:100%;
    width: 100%;
    /* margin-left: inherit; */
  }
}
body {
  overflow-x: hidden;
}

 * {
  font-family: "Roboto", sans-serif !important;
  /* margin: 0px; */
  padding: 0px;
}
 

/* ===== Scrollbar CSS ====== */

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  background-clip: padding-box;
  border: 2px solid transparent;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ababab;
}

::-webkit-scrollbar-thumb:active {
  background-color: #ababab;
}