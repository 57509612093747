@media screen and (max-width: 767px) { 
    .iframe-loaction{
        aspect-ratio: 16 / 9;
        height: 100%;
        width: 100%;
    }
    .image-responsive{
        width: 80%;
        height: 24%;
    }
}