.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.darkBGdesc {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 2000px;

  z-index: 0;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -90%); */
  position: fixed;
  top: 0;
  left: 0;
}

.learnmoremodalcondesc {
  display: flex;
  flex-direction: column;
  margin-left: 31px;
  margin-top: 23px;

  @media (max-width:600px) {
    display: block;
    margin-top: 15px !important;
    margin: 0;
    width: 100%;

    /* margin-top: 633px; */
  }

}

.learnMoreTextSec1desc {
  padding-top: 6px;
  display: flex;
  gap: 15px;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 6px 15px 15px 15px;
  }
}

/* .centereddesc {
  position: fixed;
  
  left: 50%;
  transform: translate(-50%, -50%);
} */
.centereddesc {
  position: absolute;
  transform: translate(40%, -68%);

  left: -45%;
  top: 55%;
  overflow: auto;
  /* height: 80vh; */

}

.learnmoreimagedesc {
  width: 382px;
  height: 149px;
  padding: 10px;

  @media (max-width:600px) {
    width: 94%;
    margin: auto;

  }
}

.marketsdesc {

  display: flex;
  width: 100%;
  gap: 15px;
  margin-top: 5px;

  padding-top: 4px;

  @media (max-width:600px) {
    width: 95%;
    flex-direction: row;
    gap: 5px;


  }

}

.compitavemapcontainerdesc {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -9px;

  @media (max-width:600px) {
    margin-top: 10px;
    width: 95%;
    flex-direction: column;
  }
}

.compitavemapcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width:600px) {
    margin-top: 10px;
    width: 95%;
    flex-direction: column;
  }
}

.compitavemapimagedesc {
  display: flex;
  width: 536px;
  gap: 15px;

  @media (max-width:600px) {
    flex-direction: row;
    width: 95%;

  }
}

.modaldesc {
  width: 573px;

  border-radius: 5px;
  /* top: -4px
left: -7493px */
  overflow: hidden;

  background: white;
  z-index: 10;

  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  @media (max-width: 600px) {
    width: 324px;
    height: 379px;
  }
}

.modalHeaderdesc {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.imagemarketdesc {
  width: 439px;
  height: 165px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-top: 3px;

  @media (max-width:600px) {
    width: 95%;
    height: auto
  }
}

.imagemarketdesc1 {
  width: 439px;
  height: 145px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-top: 12px;

  @media (max-width:600px) {
    width: 95%;
    height: auto
  }
}

.imagemarketdesc2 {
  width: 439px;
  height: 140px;
  padding-left: 8px;
  padding-bottom: 8px;
  padding-top: 12px;

  @media (max-width:600px) {
    width: 95%;
    height: auto
  }
}

.imagemarket {
  width: 100%;

}

.headingdesc {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.sharepopupcontentdesc {
  padding-left: 45px;

  @media (max-width: 600px) {
    padding-left: 16px;
  }
}

.modalContentdesc {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.hoverimagedesc:hover {
  background-color: #ff3e4e;
}

.modalActionsdesc {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainerdesc {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtndesc {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtndesc:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtndesc {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
}

.deleteBtndesc:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtndesc {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 0.8rem;
  border: none;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
}

.cancelBtndesc:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}

.learnmodaldesc {
  display: flex;
  flex: 1;
  flex-direction: row;
  /* width: 1214px;
height: 578px;
 background: white; */
  /* top: 769px;
left: -1184px; */

}

.learnmorebackbannerdesc {
  background-image: url("../Learnmorebanner.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  /* background-attachment: fixed; */
  background-size: cover;
}

.learnmodemodalcentereddesc {
  display: flex;
  flex-direction: row;
  width: 1214px;
  height: 578px;
  padding-bottom: 25px;
  background: white;
  position: relative;

  @media (max-width:1280px) {

    height: 500px;
    overflow: auto;


  }

  @media (max-width:600px) {
    display: flex;
    flex-direction: column;
    width: 95%;
    height: auto;
    overflow: scroll;
    /* padding-bottom: 1px; */

  }

}

.emergingtrendsdesc {
  flex: 1;
  border-left: 2px solid #ede5e5;
  margin-left: 8px;

  padding-left: 20px;
  margin-top: 50px;

  @media (max-width: 600px) {
    width: 95%;
    padding-left: 0px;
    border: none;
    margin-left: 15px;
    /* margin: 0%; */
  }
}

.emergingtrendsdesc .sectionTextdesc {
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
    box-sizing: border-box;
    margin: auto !important;
    padding: 0px 25px;
  }
}

.sectionTextLeftdesc {
  padding: 0px 10px 0px 0px;
}

.sectionRightTextdesc {
  padding: 0px 0px 0px 10px;
}

emergingtrendsdesc .image {
  width: 527px;
  height: 235px;
  padding: 15px;
  vertical-align: middle;
  box-sizing: border-box;

  @media (max-width: 600px) {
    width: 337px;
    height: auto;
    padding: 0%;
    margin: auto;
  }
}

.learnmoreenhanceimageedesc {

  width: 512px;
  height: 305px;

  /* padding-left: 21px;
     padding-top: 18px; */
  @media (max-width:600px) {
    width: 95%;
    height: auto;
    padding: 10px;

  }

}

.closeIcondesc {
  width: 25px;
  height: 25px;
  margin-top: 13px;
  margin-left: 63px;
  position: absolute;
  right: 44px;
  cursor: pointer;

  @media (max-width: 600px) {
    margin: 0%;
    z-index: 44;
    /* right: 5px;
    top: 5px; */
    background-color: #ffffff;
    padding: 6px;
    border-radius: 50%;
    height: 18px;
    width: 18px;
    top: -2px;
    right: -9px;
  }

}

.paragraphPOinssdes {
  margin-left: -16px;

  @media (max-width:"600px") {
    margin-left: 0px;
  }
}

.highlighted1 {
  font-family: Roboto;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: justified;

}

/* width: "25px",
              height: "25px",
              marginTop: "13px",
              marginLeft: "63px",
              position: "absolute",
              right: "44px",
              cursor: "pointer", */