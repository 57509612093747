.modal-popup-newsletter {
  position: relative;
  width: 630px;
  min-height: 440px;
  background: #fff;
  border: 1px solid #ccc;
  margin: 0 auto;
  margin-top: 8%;
  right: 0;
  border-radius: 8px;
  outline: none;
  /* z-index: 9999; */

  @media (min-width: 320px) and (max-width: 480px) {
    transform: scale(0.5);
    margin-left: -120px;
  }
}

.ReactModal__Overlay {
  background-color: #584f4fc9 !important;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 500ms ease-in-out;
  z-index: 999999999;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: scale(1);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(0px);
}