.svgColor:hover path {
  fill: #f3e4d2;
}

.FooterTitleBdrBtm {
  border-bottom: 0.5px solid #a5a5a5;
  padding-bottom: 12px;
}

.FooterTitleBdrBtmLongWidth {
  border-bottom: 0.5px solid #a5a5a5;
  padding-bottom: 12px;
  width: 228%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .FooterTitleBdrBtmLongWidth {
    width: initial;
  }
}

.FooterLinkMarTop-25 {
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: 25px;
  }
}

.VisibilityHideDesktop {
  visibility: hidden;
}

.FullHideMobile {
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
}
