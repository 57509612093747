.ip-temp-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
}

.ip-testimonial-wrap {
    animation: fadeIn 3s;
    -webkit-animation: fadeIn 3s;
    -moz-animation: fadeIn 3s;
    -o-animation: fadeIn 3s;
    -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

/* Slide-in animation

.ip-temp-wrap {
    position: absolute;

    left: 50%;
    transform: translate(-50%, -60%);
    animation: slideInRight 3s;
    -webkit-animation: slideInRight 3s;
    -moz-animation: slideInRight 3s;
    -o-animation: slideInRight 3s;
    -ms-animation: slideInRight 3s;
}

@keyframes slideInRight {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }

    100% {
        transform: translateX(-50%);
        opacity: 1;
    }
}

@-moz-keyframes slideInRight {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }

    100% {
        transform: translateX(-50%);
        opacity: 1;
    }
}

@-webkit-keyframes slideInRight {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }

    100% {
        transform: translateX(-50%);
        opacity: 1;
    }
}

@-o-keyframes slideInRight {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }

    100% {
        transform: translateX(-50%);
        opacity: 1;
    }
}

@-ms-keyframes slideInRight {
    0% {
        transform: translateX(0%);
        opacity: 0;
    }

    100% {
        transform: translateX(-50%);
        opacity: 1;
    }
}
*/

