.loader {
  will-change: transform;
  animation: placeHolderShimmer 3s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  background: #e6e6e6;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 15px;
}
@keyframes placeHolderShimmer {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: -468px 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: 468px 0;
  }
}
