/* .hover-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
}

.hover-modal-modal-main {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.hover-modal-display-block {
  display: block;
}

.hover-modal-display-none {
  display: none;
}

.hover-div:hover {
  cursor: pointer;
} */
.PPTCards {
  width: 1200px;
  display: flex;
  margin: auto;
  flex-wrap: wrap;
  gap: 48px;
  margin-bottom: 65px;
  margin-top: -18px;
  /* padding-left: 80px;
  margin: 0px 50px 50px 20px; */
  justify-content: center;
}

.enhanceimage {
  width: 371px;
  height: 152px;


  @media (max-width: 600px) {
    width: 100%;
  }
}

.sharecloseicon {
  margin-top: 13px;
  margin-left: 63px;
  cursor: pointer;

  @media (max-width:600px) {
    margin-left: 63px;
  }
}

.interactivecustomerrbanner {
  display: flex;
  flex-direction: column;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: start;
  height: 360px;
  width: 100%;
  padding-bottom: 45px;

  @media (max-width: 600px) {
    padding-bottom: 0px;
    margin-top: 46px;

  }
}

@media only screen and (max-width: 600px) {
  .PPTCards {
    width: 95%;
    flex-direction: column;
    padding: 0;
    align-items: center;
    margin: 32px auto 45px auto;
    gap: 5px;
  }
}

.actionbuttons {
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    justify-content: flex-start;
    margin: 10px 0px 0px 0px;
  }
}

.shareicon {
  width: 18.47px;
  height: 17.5px;

  @media (max-width: 600px) {
    width: 15px;
    height: 15px;
  }
}

.usersearch {
  width: 28px;
  height: 28px;

  @media (max-width: 600px) {
    width: 10px;
    height: 10px;
  }
}

.sampleenhache {
  width: 221px;
  height: 128px;
  margin-left: 12px;
  margin-top: 4px;
  left: 24px;

  @media (max-width: 600px) {
    width: 172.75px;
    margin-left: 6px;
    height: 70.95px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #454545;
}

.tooltip1 {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #454545;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 98px;
  /* height:44px; */

  /* padding: 3px;
  top: -25px; */
  padding: 2px;
  top: -23px;
  right: -78px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  background-color: #454545;
  color: #fff;


  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
}

.tooltip2:hover .tooltiptext2 {
  visibility: visible;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 131px !important;
  /* height:44px; */

  padding: 2px;
  top: -23px;
  right: -78px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  background-color: #454545;
  color: #fff;


  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip2 .tooltiptext2 {
  visibility: hidden;
  width: 260px !important;
  border-radius: 10px;
  /* height:44px; */
  display: block;
  padding: 10px;
  top: 258px;
  right: 56px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  background-color: #454545;
  color: #fff;

  @media (max-width:767px) {
    display: none;
  }

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.iframestyles {
  width: 100%;
  height: 584px;

  @media (max-width:767px) {
    height: 300px;

  }
}

.loaderstylespptnew {
  position: absolute;
  z-index: 10;
  background-color: #FFF;
}

.modal-popup-back-story1 {
  margin: 0 auto;
  margin-top: 1%;
  height: 529px !important;
  border: none;
  outline: none;
  /* overflow-y: auto;
  overflow-x: hidden; */
  width: 1200px;
}

.modal-popup-back-share {
  margin: 0 auto;
  margin-top: 3%;
  height: 529px !important;
  border: none;
  outline: none;
  overflow-y: auto;
  overflow-x: hidden;
  width: 586px;

  @media(max-width:600px) {

    width: auto;
    margin-top: 25%;
    /* margin-left: 12%; */

  }

}

@media (max-width: 600px) {
  .modal-popup-back-story1 {
    margin-top: 22%;
    margin-left: 11px;
    overflow-x: hidden;
    width: auto;
  }

}