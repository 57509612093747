.video {
    aspect-ratio: 16 / 9;
    width: 100%;
  }


  .mandatory {
    color: #FF4141;
  }

  .error {
    color: #FF4141;
  }

  .link {
    color: #0E50D5;
    text-decoration: none;
  }

 


  

  /* Utility Class */

  .mt-70 {
    margin-top: 70px;
  }

  .mt-100 {
    margin-top: 100px;
  }

  .zindex1 {
    position: relative;
    z-index: 1;
  }

  .zindex2 {
    position: relative;
    z-index: 2;
  }

  .zindex3 {
    position: relative;
    z-index: 3;
  }

  .fs-12 {
    font-size: 12px;
  }

  /* Overlay Class */

  .CardOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 25px;
    width: 279px !important;
    height: 364px !important;
    padding: 1px;
  }
  
  /* Table CSS */

.ReportCoverageTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #F2651B;
  margin: 20px 0;
}
.ReportCoverageTableHeader {
  background-color: #A9603B;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  vertical-align: middle;
}

.ReportCoverageTableData {
  padding: 10px;
  border: 1px solid #A9603B;
  background-color: #fff;
}

.ReportCoverageTableLeftData {
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; 
  padding: 20px 0 20px 30px;
  vertical-align: middle;
}
  

.ReportCoverageTableRightData {
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  padding: 20px 0 20px 30px;
  vertical-align: middle;
}


.PriceTable {
  width: 100%;
  border-collapse: collapse;
  /* border: 1px solid #BCBCBC; */
  margin: 20px 0;
}


.PriceTableHeaderGray {
  background-color: #E3E3E3;
  border-radius: 25px 25px 0px 0px;
  border: 1px solid #8F8F8F;
  padding-top: 50px;
  padding-bottom: 40px;
  color: #000000;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  vertical-align: middle;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
}


.PriceTableHeaderBlue {
  background-color: #C2E2FF;
  border-radius: 25px 25px 0px 0px;
  border: 1px solid #8F8F8F;
  padding-top: 50px;
  padding-bottom: 40px;
  color: #28669E;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  vertical-align: middle;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

.PriceTableHeaderMaroon {
  background-color: #F7D8D6;;
  border-radius: 25px 25px 0px 0px;
  border: 1px solid #8F8F8F;
  padding-top: 50px;
  padding-bottom: 40px;
  color: #AB413A;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  vertical-align: middle;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

.PriceTableHeaderData {
  padding: 10px;
  border: 1px solid #BCBCBC;
  /* background-color: #fff; */
}

.DataDarkBackground {
  background: #454545;
}

.DataLightBackground {
  background: #ffffff;
}

.DataGrayBackground {
  background: #F7F7F7;
}

.DataBlueBackground {
  background: #F4FAFF;
}

.DataMaroonBackground {
  background: #FFEEED;
}


.DataDarkText{
  color: #FFF;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  padding: 30px;
  vertical-align: middle;
  text-align: center;
}

.DataGrayText{
  color: #000;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 
  padding: 30px;
  vertical-align: middle;
  text-align: center;
}

.DataBlueText{
  color: #0E5CA3;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 
  padding: 30px;
  vertical-align: middle;
  text-align: center;
}

.DataMaroonText{
  color: #AB413A;
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; 
  padding: 30px;
  vertical-align: middle;
  text-align: center;
}

.fs15 {
  font-size: 15px;
}

.fs20 {
  font-size: 20px;
}

.fs34 {
  font-size: 34px;
}

.fw-400 {
  font-weight: 400 !important;
}

.ol-bullet {
  color: #FFF;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

@media (min-width: 320px) and (max-width: 480px) {
  .ReportWidth {
    width: 330px;
    margin: 0 auto;
 
  }
 
}

@media screen and (max-width: 992px) {

  .ReportWidth {
    width: 300px;
    margin: 0 auto;
 
  }
}

@media screen and (max-width: 1200px) {

  .ReportWidth {
    width: 280px;
    margin: 0 auto;
 
  }
}
