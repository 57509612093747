.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

body {
  overflow-y: scroll;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.ecosystem {
  margin-left: 44px;

  @media (max-width: 600px) {
    margin-left: 0px;
    min-height: 550px;
  }
}

.countriess::after {
  content: ","
}

.countriess:last-child:after {
  content: ""
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

/* . */
.imageshape {
  background-color: #f99731;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 59px;
  padding-left: 24px;

  width: 80px;
  clip-path: polygon(0 1%, 52% 0, 100% 100%, 0% 100%);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.profileImage {
  width: 91px;
  height: 91px;
  transform: translateX(-16px);
}

@media (max-width: 600px) {
  .imageshape {
    width: 60px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 59px;
    padding-left: 24px;
  }

  .profileImage {
    width: 80px;
    height: 80px;
  }
}

.aipoints {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0px;
  text-align: left;
  color: #454545;
  line-height: 50px;

  @media (width: 600px) {
    font-size: 18px;
  }
}

.aiipointslist {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 19px;
}

.aiHealightpoints {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0px;
  text-align: left;
  /* color: #454545; */
  color: rgba(255, 118, 36, 1) !important;
  margin-left: 4px;

  @media (width: 600px) {
    font-size: 18px;
  }
}

.searchIcon {
  position: absolute;
  top: 12px;
  right: 16px;
  transition: all 1s;
  transition-timing-function: cubic-bezier(0, 1, .64, .91);
}


.loader-card {
  display: flex;
  flex-direction: row;
  @media (width: 600px) {
    width: 100%;
    background-color: blue;
  }
}

.smalldesc {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 400;
  line-height: 0px;
  letter-spacing: 0em;
  text-align: left;
  color: #454545;
  padding-left: 9px;
}

.keypersons {
  padding: 5px 5px 8px 1px;
  height: 49px;
  padding-bottom: 5px;
  border-radius: 3.53px;
  border: 0.88px solid rgba(41, 112, 177, 1);
}

.smalltext {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 700;
  padding-left: 10px;
  line-height: 7px;
  letter-spacing: 2.648120403289795px;
  text-align: left;
  text-transform: uppercase;
  color: #352960;
}

.sendAside {
  position: absolute;
  top: 10px;
  right: 10px;
  margin: 0;
}

.loaderNew {
  will-change: transform;
  animation: placeHolderShimmer 3s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  background: #e6e6e6;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  position: relative;
  border-radius: 5px;
}

.loaderNew1 {
  will-change: transform;
  animation: placeHolderShimmer 3s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  background: #e6e6e6;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  position: relative;
  border-radius: 5px;
  width: 143px;
  height: 100%;
  border-radius: 5px 5px 1px 1px;

  @media (max-width: 600px) {
    margin-top: -40px;
  }
}

.year {
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  color: #454545;
}

.IconsHover:hover {
  border-radius: 4px;
  background: rgba(189, 223, 255, 1);
}

.tooltipnoverty-trigger .tooltipnoverty {
  width: 361px;
  height: 309x;
  padding-left: 28px;

  border-radius: 12px;


  background-color: rgba(0, 0, 0, 1);
  color: #fff;
  text-align: center;

  display: none;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
  top: 28px;
  min-width: 65px;
  right: -85px;
}

.tooltipnoverty-trigger:hover .tooltipnoverty {
  display: block;
}

.tooltipnoverty-trigger {
  position: relative;
  display: inline;
}

.tooltip-trigger .tooltip {
  width: max-content;
  background-color: #454545;
  color: #fff;
  text-align: center;
  padding: 10px;
  display: none;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  font-weight: 400;
  top: 40px;
  min-width: 65px;
  right: -10px;
}

.tooltip-trigger:hover .tooltip {
  display: block;
}

.tooltip-trigger {
  position: relative;
  display: inline;
}

.nodatatext {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 2px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(172, 172, 172, 1);
}

@media screen and (max-width: 767px) {
  .tooltip-trigger:hover .tooltip {
    display: none;
  }
}

.fly-card {
  position: absolute;
  width: 174px;
  height: 194px;
  box-shadow: 0px 2px 6px 0px rgba(69, 69, 69, 0.25);
  top: -125px;
  left: 15px;
  z-index: 100;

  @media(max-width: 600px) {
    top: -40px;
    width: 138px;
    box-shadow: none;
  }
}

.flyyy {

  display: flex;
  flex-direction: column;
  @media(max-width:600px) {
    flex-direction: row;
  }
}

.dataprocessing {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  margin-left: 20px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(0, 0, 0, 1);

  @media (max-width: 600px) {
    margin-top: 190px;
    margin-left: 0px;
  }
}

.arroww {
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: rgb(166, 165, 167);
}

.cardbackground {
  height: 1216px;
}

.pointsnovertyy {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(200, 200, 200, 1);
  display: flex;
  align-items: center;
  gap: 8px;



}

.listneww {
  list-style-type: none;

  padding-left: 0px;
}

.listneww li::before {
  content: '\2022';
  color: rgba(150, 150, 150, 1);
  font-size: 40px;


}

/* cardss animation*/

.list {
  display: flex;
  margin: auto;
  gap: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 15em;
}

.ImageForFinancial {
  width: 57px;
  height: 57px;
}

.ImageForEcoSystem {
  width: 127px;
  height: 66px;
}

.overviewcontent {
  width: 154px;
  padding: 10px;
  top: 149.58px;
  border-radius: 0px, 0px, 5.16px, 5.16px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  background-color: rgba(69, 69, 69, 0.9);

  @media (max-width: 600px) {
    width: 138px;
  }
}

.imagefly {
  width: 100%;
  @media (max-width: 600px) {
    padding: 15px;
  }
}

.listlist {
  margin-left: 59px;

  @media (max-width: 600px) {
    margin-left: 0px;
  }
}

.chatss {
  display: flex;
  flex-direction: row;
  margin-top: 48px;
  gap: 38px;
  margin-bottom: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.industriessproduct {
  display: flex;
  flex-direction: row;
  margin-top: 35px;
  gap: 84px;

  @media (max-width: 600px) {
    margin-top: 70px;
  }
}

.listlist1 {
  margin-left: -12px;

  @media (max-width: 600px) {
    margin-left: 0px;
  }
}

.ImageWraperForHeader {
  width: 38px;
  height: 18.42px;
  margin: 9px 0px 0px 0px;
  padding: 9px;
}



.profileAside {
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
  z-index: 10;
}


.flycardgap {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
  width: 170px;

  @media (max-width:600px) {
    flex-direction: row;
    width: 100vw;
    overflow: hidden;
  }
}

.profileAsideWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width:600px) {
    flex-direction: row;
    width: 100px;
  }
}

.absolute {
  position: absolute;
  top: 0px;
}

.left-1 {
  left: 190px;
  z-index: 5;
}

.left-2 {
  left: 200px;
  z-index: 4;
}

.left-3 {
  left: 210px;
  z-index: 3;
}

.left-4 {
  left: 220px;
  z-index: 2;
}

.left-5 {
  left: 230px;
  z-index: 1;
}

.profileAside>img {
  width: 100%;
}

.morebuttonnew {
  outline: none;
  color: rgba(69, 69, 69, 1);
  border: none;
  background: white;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

@media (max-width:600px) {
  .profileAside>img {
    width: auto;
  }
}

.profileAside>div {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0px, 0px, 5.16px, 5.16px;
}

.imageWrapperForProductDetails {
  Width: 180px;
  Height: 180px;
}

.ImageWrapperFirstPage {
  height: 125px;
}

.application-list>li {
  line-height: 24px;
}

.DebiImageWrapper {
  width: 60px;
  height: 560px;
}

.width-industry {
  width: 249px;
}

.no-comma::after {
  content: "" !important;
}

@media (max-width:600px) {
  .width-industry {
    width: auto;
  }
}

.ecosystemsupplierwrapper {
  display: flex;
  flex-direction: row;
  gap: 44px;
  flex-wrap: wrap;
  row-gap: 0;

  @media (max-width:600px) {
    gap: 25px;

  }
}

.sourceloader {
  will-change: transform;
  animation: placeHolderShimmer 1s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  background: #e6e6e6;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  position: relative;
  border-radius: 5px;
  margin-bottom: 15px;
}

@keyframes placeHolderShimmer {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: -468px 0
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: 468px 0
  }
}